import React from 'react';

import Layout from '../components/Layout';

import '../assets/styles/pages/privacy-poilicy.scss';

const PrivacyPolicy = () => (
  <Layout
    title="Política de Privacidad | ELE Barcelona"
    description="Tratamiento y protección de datos (alojamiento, requisitos para navegación, política anti-spam, etc) cuando navegas en esta web https://www.elebarcelona.com/"
  >
    <div className="privacy-policy">
      <section className="first-section content">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <h1 className="c-title-34 title">Política de privacidad</h1>
              <div className="section">
                <h4 className="subtitle">Introducción</h4>
                <p className="c-text-18">
                  Esta política de privacidad establece cómo ELE Barcelona utiliza y protege
                  cualquier información que usted le proporcione a ELE Barcelona cuando utiliza este
                  sitio web. Estamos comprometidos a garantizar que su privacidad esté protegida. Si
                  le solicitamos que proporcione cierta información mediante la cual pueda ser
                  identificado al utilizar este sitio web, puede estar seguro de que sólo se
                  utilizará de acuerdo con esta declaración de privacidad.
                  <br />
                  <br />
                  Podemos cambiar esta política de vez en cuando al actualizar esta página. Le
                  comunicaremos cualquier cambio mediante la publicación de una nota en nuestro
                  sitio web. Esta política actualizada entra en vigencia a partir del 25 de abril de
                  2023.
                </p>
              </div>
              <div className="section">
                <h4 className="subtitle">
                  ¿Quién es el responsable del tratamiento de sus datos personales?
                </h4>
                <p className="c-text-18">
                  <a href="#" className="external-link">
                    <span className="colored no-link">Trinity Language Academy SL, CIF:</span>{' '}
                    B67330431.
                  </a>
                  <a href="#" className="external-link">
                    <span className="colored no-link">Dirección:</span> Calle Valencia 275, 3º,
                    Barcelona 08009.
                  </a>
                  <a href="tel:+34934875116" className="external-link">
                    <span className="colored no-link ">Phone:</span> (+34) 934875116
                  </a>
                  <a href="mailto:formacion@elebarcelona.com" className="external-link">
                    <span className="colored no-link ">Email:</span> formacion@elebarcelona.com
                  </a>
                </p>
              </div>
              <div className="section">
                <h4 className="subtitle">¿Qué datos recopilamos?</h4>
                <p className="c-text-18">
                  <ul className="list">
                    <li>Nombre y apellidos</li>
                    <li> Dirección postal (incluidas las direcciones de facturación y envío)</li>
                    <li>Número de teléfono (incluidos los números de teléfono de casa y móvil)</li>
                    <li>Dirección de correo electrónico</li>
                    <li>País de residencia</li>
                    <li>Dirección IP</li>
                    <li>Información del navegador y del dispositivo</li>
                    <li>Información del archivo de registro del servidor</li>
                    <li>
                      Información recopilada a través de cookies, etiquetas de píxeles y otras
                      tecnologías
                    </li>
                    <li>Información demográfica y otra información proporcionada por usted</li>
                    <li>Información sobre la ubicación</li>
                  </ul>
                </p>
              </div>
              <div className="section">
                <h4 className="subtitle">¿Cómo recopilamos sus datos?</h4>
                <p className="c-text-18">
                  A través de nuestro sitio web: cuando completa un formulario de contacto, por
                  ejemplo. Fuera de línea: cuando llama o visita la escuela.
                  <br />
                  <br />A través de su navegador o dispositivo: la mayoría de los navegadores
                  recopilan cierta información o automáticamente a través de su dispositivo.
                  Utilizamos esta información con fines estadísticos y para asegurarnos de que
                  nuestro sitio web funcione correctamente. De otras fuentes: de escuelas y
                  organizaciones asociadas, por ejemplo, si solicita información sobre ELE Barcelona
                  a través de GoAbroad o un anuncio en Facebook.
                </p>
              </div>
              <div className="section">
                <h4 className="subtitle">Qué hacemos con la información que recopilamos</h4>
                <p className="c-text-18">
                  Requerimos esta información para comprender sus necesidades y brindarle un mejor
                  servicio, y en particular por las siguientes razones:
                  <br />
                  <br />
                  <ul className="list">
                    <li>Mantenimiento de registros internos.</li>
                    <li>
                      Podemos utilizar la información para mejorar nuestros productos y servicios.
                    </li>
                    <li>
                      Es posible que enviemos periódicamente correos electrónicos promocionales
                      sobre nuevos productos, ofertas especiales u otra información que creemos que
                      le puede resultar interesante utilizando la dirección de correo electrónico
                      que nos ha proporcionado.
                    </li>
                    <li>
                      {' '}
                      De vez en cuando, también podemos utilizar su información para comunicarnos
                      con usted con fines de investigación de mercado. Podemos comunicarnos con
                      usted por correo electrónico o por teléfono. Podemos utilizar la información
                      para personalizar el sitio web de acuerdo con sus intereses.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="section">
                <h2 className="name c-title-34">Seguridad</h2>
                <p className="c-text-18">
                  Estamos comprometidos a garantizar que su información esté segura. Para evitar el
                  acceso o la divulgación no autorizados, hemos implementado procedimientos físicos,
                  electrónicos y administrativos adecuados para salvaguardar y asegurar la
                  información que recopilamos en línea.
                </p>
              </div>
              <div className="section">
                <h2 className="subtitle">Cómo usamos las cookies</h2>
                <p className="c-text-18">
                  Una cookie es un pequeño archivo que solicita permiso para colocarse en el disco
                  duro de su computadora. Una vez que se acepta, se agrega el archivo y la cookie
                  ayuda a analizar el tráfico web o le permite saber cuándo visita un sitio en
                  particular. Las cookies permiten que las aplicaciones web le respondan como un
                  individuo. La aplicación web puede adaptar sus operaciones a sus necesidades,
                  gustos y disgustos al recopilar y recordar información sobre sus preferencias.
                  <br />
                  <br />
                  Usamos cookies de registro de tráfico para identificar qué páginas se están
                  utilizando. Esto nos ayuda a analizar datos sobre el tráfico de la página web y
                  mejorar nuestro sitio web para adaptarlo a las necesidades del cliente. Solo
                  usamos esta información con fines de análisis estadístico y luego los datos se
                  eliminan del sistema.
                  <br />
                  <br />
                  En general, las cookies nos ayudan a brindarle un mejor sitio web al permitirnos
                  monitorear qué páginas le resultan útiles y cuáles no. Una cookie de ninguna
                  manera nos da acceso a su computadora ni a ninguna información sobre usted, aparte
                  de los datos que elija compartir con nosotros.
                  <br />
                  <br />
                  Puede optar por aceptar o rechazar las cookies. La mayoría de los navegadores web
                  aceptan automáticamente las cookies, pero normalmente puede modificar la
                  configuración de su navegador para rechazar las cookies si lo prefiere. Esto puede
                  impedir que aproveche al máximo el sitio web.
                </p>
              </div>
              <div className="section">
                <h2 className="subtitle">Enlaces a otros sitios web</h2>
                <p className="c-text-18">
                  Nuestro sitio web puede contener enlaces a otros sitios web de interés. Sin
                  embargo, una vez que haya utilizado estos enlaces para salir de nuestro sitio,
                  debe tener en cuenta que
                  <br />
                  <br />
                  no tenemos ningún control sobre ese otro sitio web. Por lo tanto, no podemos ser
                  responsables de la protección y privacidad de cualquier información que usted
                  proporcione mientras visita dichos sitios y dichos sitios no se rigen por esta
                  declaración de privacidad. Debe tener cuidado y consultar la declaración de
                  privacidad aplicable al sitio web en cuestión.
                </p>
              </div>
              <div className="section">
                <h2 className="subtitle">¿Cuánto tiempo conservaremos sus datos?</h2>
                <p className="c-text-18">
                  Los datos personales que proporciones se conservarán mientras no solicites su
                  eliminación.
                </p>
              </div>
              <div className="section">
                <h2 className="subtitle">¿A qué organizaciones se transmitirá su información?</h2>
                <p className="c-text-18">
                  Las únicas organizaciones a las que transmitimos sus datos son las que están
                  estrechamente relacionadas con ELE Barcelona y los servicios que brindamos, como
                  alojamiento y cursos impartidos en escuelas asociadas.
                </p>
              </div>
              <div className="section">
                <h2 className="subtitle">Las redes sociales</h2>
                <p className="c-text-18">
                  Regularmente se toman fotografías en las instalaciones de ELE Barcelona y fuera de
                  la escuela, relacionadas con las actividades escolares. Estos pueden publicarse en
                  nuestros canales de redes sociales. Tiene derecho a solicitar que su imagen no se
                  utilice en nuestros canales.
                </p>
              </div>
              <div className="section">
                <h2 className="subtitle">Controlando su información personal</h2>
                <p className="c-text-18">
                  Puede optar por restringir la recopilación o el uso de su información personal de
                  las siguientes maneras:
                  <br />
                  <br />
                  <ul className="list">
                    <li>
                      Si previamente ha aceptado que usemos su información personal con fines de
                      marketing directo, puede cambiar de opinión en cualquier momento
                      escribiéndonos o enviándonos un correo electrónico a
                      formacion@elebarcelona.com.
                    </li>
                    <li>
                      No venderemos, distribuiremos ni cederemos su información personal a terceros
                      a menos que tengamos su permiso o la ley nos exija hacerlo. Podemos utilizar
                      su información personal para enviarle información promocional sobre terceros
                      que creemos que le puede resultar interesante si nos dice que desea que esto
                      suceda.
                    </li>
                  </ul>
                  <br />
                  Si cree que la información que tenemos sobre usted es incorrecta o está
                  incompleta, escríbanos o envíenos un correo electrónico lo antes posible a la
                  dirección anterior. Corregiremos de inmediato cualquier información que resulte
                  incorrecta.
                </p>
              </div>
              <div className="section">
                <h2 className="subtitle">Nota</h2>
                <p className="c-text-18">
                  Navegar por este sitio web, o utilizar cualquiera de los servicios que se brindan
                  a través del mismo, implica la aceptación de la política descrita en estas
                  páginas.
                  <br />
                  <br />
                  Última actualización: 25 de abril de 2021, en cumplimiento del Reglamento General
                  de Protección de Datos (UE) 2016/679.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default PrivacyPolicy;
